<template>
    <div class="cs-nested-link">
        <span
            v-if="link.children && Object.keys(link.children).length"
            data-toggle="collapse"
            :href="`#nested-collapsible-${index}`"
            role="button"
            aria-expanded="false"
            :aria-controls="`nested-collapsible-${index}`"
            :class="activeClass"
            @click="toggleLink"
        >
            {{ link.title }}
            <svg v-if="link.children && Object.keys(link.children).length" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7.5L10 12.5L15 7.5H5Z" fill="#13314E"/>
            </svg>
        </span>
        <a v-else :href="link.url" target="_blank">
            {{ link.title }}
        </a>
        <div v-if="link.children && Object.keys(link.children).length">
            <div class="collapse" :id="`nested-collapsible-${index}`">
                <NestedLink v-for="(sublink, subIndex) in link.children" :index="`${index}-${subIndex}`" :link="sublink" :key="`${index}-${subIndex}-level-link-${index}`" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NestedLink",
        components: {
            NestedLink: () => import('./NestedLink.vue')
        },
        props: {
            link: {
                type: Object
            },
            index: {
                type: [Number, String]
            }
        },
        data() {
            return {
                amIActive: false
            }
        },
        computed: {
            activeClass() {
                let result = {};

                if (this.amIActive) result['active'] = true;

                return result;
            }
        },
        methods: {
            toggleLink() {
                this.amIActive = !this.amIActive;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../styles/main.scss";
    .cs-nested-link {
        span,
        a {
            color: white;
            font-size: 25px;
            margin-bottom: $default_margin;
            cursor: pointer;
            padding: 8px 10px;
            transition: background-color 300ms ease-out;
            border-radius: 8px;
            display: flex;
            align-items: center;
            user-select: none;
            text-decoration: none;
            outline: none;
            position: relative;
            width: 100%;
            text-transform: capitalize;
            align-items: center;

            svg {
                margin-left: auto;
                display: block;
                transition-duration: 300ms;
                transition-property: transform;
                path{
                    fill:white;
                }
            }

            &.active,
            &:hover,
            &:focus-visible,
            &:focus-within,
            &:focus {
                text-decoration: none;
                outline: none;
            }

            &:hover,
            &.active {
                background-color: $first_navmenu_action_color;
            }

            &.active {
                svg {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);

                }
            }

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
        
        .cs-nested-link {
            padding-left: $default_margin;
        }
    }
</style>